export default defineNuxtRouteMiddleware((to) => {
    // Redirect event + news overview pages to center overview page
    const redirectContentPages = ['centerUid-events', 'centerUid-news']

    if (redirectContentPages.includes(to.name)) {
        return navigateTo(`/${to.params.centerUid}`);
    }

    // Redirect coupon detail page to coupon overview page
    const redirectCouponDetail = ['centerUid-coupons-couponUid'];

    if (redirectCouponDetail.includes(to.name)) {
        return navigateTo(`/${to.params.centerUid}/coupons?showAppTeaser=true`);
    }
});
